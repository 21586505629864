import React from 'react';
import { Section, Title, Text } from '../components/Core';
import { Col, Container, Row } from 'react-bootstrap';
import FullScreenLoader from '../components/FullScreenLoader';
import Seo from '../components/Seo/Seo';
import BdukForm from '../components/Forms/BdukForm';

const RegisterBdukPage = () => {
    return (
        <>
            <Seo page="register" featuredImageUrl={undefined} />
            <FullScreenLoader title="Processing registration ..." />
            <Section>
                <Container>
                    <Row>
                        <Col sm={12} className="text-center pt-5 mt-3 pb-1 mb-5">
                            <Title variant={undefined}>
                                You're eligible for a gigabit broadband voucher!
                            </Title>
                            <Text variant={undefined}>
                                As part of the Government's commitment to provide gigabit-capable broadband to
                                85% of the country, you are eligible to claim for a <a href="/gigabit-voucher-scheme" target="_blank">Gigabit Broadband Voucher</a>.
                            </Text>
                            <Text variant={undefined} className="mt-3">
                                By registering your details with us, we can claim funding from the Government
                                to build our network to your home at no extra charge to yourself.
                                This funding will only be reimbursed to us 10 days after your installation.
                            </Text>
                            <Text variant={undefined} className="mt-3">
                                Please leave your details below to show your interest in receiving ultrafast broadband:
                            </Text>
                        </Col>
                    </Row>
                    <BdukForm/>
                </Container>
            </Section>
        </>
    )

}

export default RegisterBdukPage;
