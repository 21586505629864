import { getProperty } from "./helperFn";

export const getOrderJourneyTag = (salesStatus) => {
    switch (salesStatus) {
        case 'ORDER':
            return '?s=1'
        case 'PRE_ORDER':
            return '?s=2'
        case 'REGISTER_INTEREST':
            return '?s=3'
        case null:
            return '?s=3'
        case 'REGISTER_INTEREST_PLUS':
            return '?s=4'
        default:
            return '?s=3'
    }
}

export const createLeadContactBillingAddressPayload = (RegistrationData, FormData, source) => {

    const utmData = JSON.parse(localStorage.getItem('utmData'));

    return [{
        entity: "CrmModule:Lead",
        title: getProperty(RegistrationData.SearchPremiseResults, 'title'),
        schemaId: source === 'YF' ? 'dc2440a6-651a-4013-aaaa-c54535873075' : '579b5e3a-c79d-4419-ab74-b12b8015c2b7',
        properties: {
            Type: FormData.businessSwitch ? 'BUSINESS' : 'RESIDENTIAL',
            Name: getProperty(RegistrationData.SearchPremiseResults.properties, 'FullAddress'),
            Source: RegistrationData.Source,
            EmailAddress: FormData.email,
            UtmCampaign: utmData ? utmData.utmCampaign : undefined,
            UtmMedium: utmData ? utmData.utmMedium : undefined,
            UtmSource: utmData ? utmData.utmSource : undefined,
            UtmContent: utmData ? utmData.utmContent : undefined,
            UtmTerm: utmData ? utmData.utmTerm : undefined,
            LandingPage: 'netomnia',
            CompanyName: FormData.companyName,
            CompanyPosition: FormData.companyPosition,
            CurrentSpeed: FormData.currentSpeed,
            DateOfConsent: FormData.dateOfConsent,
            UTRNumber: FormData.utrNumber,
            VAT: FormData.vat ? FormData.vat : null,
            NumberOfMonthsLeft: FormData.numberOfMonthsLeft
        },
        associations: [
            {
                entity: 'ProductModule:Offer',
                recordId: RegistrationData.offerId,
            },
        ],
    },
    {
        entity: "CrmModule:Contact",
        title: `${FormData.firstName} ${FormData.lastName}`,
        schemaId: source === 'YF' ? 'e38bfb72-a9ee-4cfd-8dba-138bc26e8a5a' : 'a9984f13-0317-4e5d-b435-ef5b03c2dd1e',
        properties: {
            CompanyName: FormData.companyName ? FormData.companyName : null,
            CompanyPosition: FormData.companyPosition ? FormData.companyPosition : null,
            EmailAddress: FormData.email,
            Title: FormData.title,
            LastName: FormData.lastName,
            FirstName: FormData.firstName,
            Phone: FormData.phoneNumber,
            Mobile: FormData.mobileNumber,
            PreviousProvider: RegistrationData?.currentProvider ? (RegistrationData?.currentProvider === 'Other' ? RegistrationData?.currentProviderOther : RegistrationData?.currentProvider) : null,
            CurrentContractType: RegistrationData?.currentContractType ? RegistrationData?.currentContractType : (RegistrationData?.personalData?.currentProvider ? 'IN_CONTRACT' : 'OUT_OF_CONTRACT'),
            HearAboutUs: FormData.hearAboutUsValue,
            HearAboutUsOther: FormData.hearAboutUsOther,
            TwilioVerifiedPhone: true,
            TermsAndConditionsConsent: FormData?.agreementSwitch || false,
            DirectDebitConsent: FormData?.directDebitConsent || false,
            HomeownerStatus: FormData?.homeownerStatus || null,
            MarketingConsent: FormData?.marketingConsent || false,
            PreferredSpeed: FormData?.preferredSpeed
        }
    }, {
        entity: "CrmModule:Address",
        title: getProperty(RegistrationData.SearchPremiseResults.properties, 'FullAddress'),
        schemaId: source === 'YF' ? '89dceeaf-3990-48e7-b914-247ac91b6e3c' : 'f74f255e-bec8-417b-af45-6439ee4f37f0',
        properties: {
            FullAddress: getProperty(RegistrationData.SearchPremiseResults.properties, 'FullAddress'),
            City: getProperty(RegistrationData.SearchPremiseResults.properties, 'PostTown'),
            AddressLine1: getProperty(RegistrationData.SearchPremiseResults.properties, 'AddressLine1') ? getProperty(RegistrationData.SearchPremiseResults.properties, 'AddressLine1') : null,
            AddressLine2: getProperty(RegistrationData.SearchPremiseResults.properties, 'AddressLine2') ? getProperty(RegistrationData.SearchPremiseResults.properties, 'AddressLine2') : null,
            Type: "BILLING",
            PostalCode: getProperty(RegistrationData.SearchPremiseResults.properties, 'PostalCode'),
            UDPRN: getProperty(RegistrationData.SearchPremiseResults.properties, 'UDPRN'),
            UMPRN: getProperty(RegistrationData.SearchPremiseResults.properties, 'UMPRN'),
            SPRN: getProperty(RegistrationData.SearchPremiseResults.properties, 'SPRN'),
            UPRN: getProperty(RegistrationData.SearchPremiseResults.properties, 'UPRN'),
            CountryCode: "GB",
            SalesStatus: getProperty(RegistrationData.SearchPremiseResults.properties, 'SalesStatus')
        }
    }]
}

export const extractLeadIdFromAPIResponse = (response) => {
    return response.find(item => {
        return item.entity === "CrmModule:Lead"
    }).id
}

export const extractContactIdFromAPIResponse = (response) => {
    return response.find(item => {
        return item.entity === "CrmModule:Contact"
    }).id
}

export const extractAddressIdFromAPIResponse = (response) => {
    return response.find(item => {
        return item.entity === "CrmModule:Address"
    }).id
}

export const prepareProductsForOdin = (rawOffer, productIds) => {
    const filteredProducts = rawOffer.dbRecords.filter(product => productIds.includes(product.id))

    if (filteredProducts) {
        return filteredProducts.map((product) => ({
            quantity: 1,
            recordId: product.id,
            relatedAssociationId: product.dbRecordAssociation && product.dbRecordAssociation.relatedAssociationId
                ? product.dbRecordAssociation.relatedAssociationId
                : null
        }))

    }
}

export const prepareProductForLeadUpdate = (filteredProduct) => {

    if (filteredProduct) {
        return [{
            quantity: 1,
            entity: 'ProductModule:Product',
            recordId: filteredProduct.id,
            relatedAssociationId: filteredProduct.dbRecordAssociation && filteredProduct.dbRecordAssociation.relatedAssociationId
                ? filteredProduct.dbRecordAssociation.relatedAssociationId
                : null
        }]
    }
}