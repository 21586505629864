import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Input, Select, Text, Title, Switch } from "../Core";
import styled from "styled-components";
import { AgreementSwitch, BusinessSwitch, MarketingConsent } from "../Core/Switch"
import { Col, Row, FormControl } from "react-bootstrap";
import { Form, Formik } from "formik"
import GlobalContext from './../../context/GlobalContext';
import { getProperty, emailRegex } from "../../utils/helperFn";
import * as yup from "yup";
import { graphql, useStaticQuery } from "gatsby";
import Link from "gatsby-link";

import API from './../../api/api';
import APINO from './../../api/api-no';

import { RetrieveDefaultProduct } from '../../utils/OdinPricingHelpers';

import {
    createLeadContactBillingAddressPayload,
    extractLeadIdFromAPIResponse,
    extractContactIdFromAPIResponse,
    extractAddressIdFromAPIResponse,
} from '../../utils/OdinOrderHelpers';
import { prepareProductForLeadUpdate } from '../../utils/OdinOrderHelpers';
import { navigate } from 'gatsby';

const LinkToPrivacyPolicy = () => {
    return <Link style={{ display: 'inline-block' }} to="/legal/privacy-and-cookie-policy">Privacy Policy</Link>;
}

const LegalSwitchCard = ({
    color = "primary",
    className,
    iconName,
    title,
    text,
    children,
    ...rest
}) => (
    <Box
        bg="light"
        border="1px solid"
        borderColor="border"
        borderRadius={10}
        className={`d-flex ${className}`}
        {...rest}
        css={`
          min-width: 100%;
          width: 100%;
          .legal-text {
            font-size: 1em;
            line-height: 1.5em;
          }
        `}
    >
        <div key={title}>
            <Title variant="card" mb={2}>
                {title}
            </Title>
            <Text className="legal-text pt-3 pl-0 pr-0 pl-lg-5 pr-0 pr-md-5">
                {
                    text.map((item, key) => {
                        if (key === 0 || (key < (text.length - 1))) {
                            return <span key={`${text}span1`}>
                                <span key={`${text}span2`}>{item}</span>
                                <LinkToPrivacyPolicy />
                            </span>
                        } else {
                            return <span>{item}</span>;
                        }
                    })
                }
            </Text>
            {children}
        </div>
    </Box>
);

function validateFeedback(feedback) {
    console.log('validateFeedback feedback: ', feedback)
    if (feedback) {
        return <FormControl.Feedback
            type="invalid"
            className="pl-1">{feedback}
        </FormControl.Feedback>
    } else {
        return <></>
    }
}

function validateAgreement(feedback) {
    if (feedback) {
        return <FormControl.Feedback
            type="invalid"
            className="pl-1 agreement-validate">{feedback}
        </FormControl.Feedback>
    } else {
        return <></>
    }
}

function validateDropdown(feedback) {
    if (feedback) {
        return <label className="invalid-label">{feedback}</label>
    } else {
        return <></>
    }
}

const constructHearAboutUsPlaceholder = (hearAboutUsValue) => {
    switch (hearAboutUsValue) {
        case 'Other':
            return "Tell us a bit more..."
        default:
            return ""
    }
}

const BdukForm = (props) => {
    const gContext = useContext(GlobalContext);
    const [inContractField, setInContractField] = useState(false);
    const [switchingProviderAdditionalField, setSwitchingProviderAdditionalField] = useState(false);
    const [hearAboutUsAdditionalField, setHearAboutUsAdditionalField] = useState(false);
    const [preferredSpeeds, setPreferredSpeeds] = useState([]);
    let leadID = null

    useEffect(() => {

        const bduk = getProperty(gContext.goGetRegistrationData()?.SearchPremiseResults?.properties, 'IsBDUK')
        if (!bduk || bduk !== "true") {
            navigate('/')
        }

        API
            /* 1. Get the offer passed from the Pricing section */
            .get(`/ProductModule/v1.0/cst/Offer/active/RESIDENTIAL?filters=ContractType:CONTRACT,IsDefault:true`)
            .then(response => {
                const products = response?.data?.data?.Product?.dbRecords;
                if (products) {
                    let options = products.filter(product => product.properties.Type === 'BASE_PRODUCT');
                    options = options.map(prod => ({
                        value: parseFloat(prod.properties.Description),
                        label: (prod.properties.Description + ' Mbps')
                    })).sort((a, b) => a.value - b.value);
                    setPreferredSpeeds(options)

                    gContext.goSetRegistrationData({
                        ...gContext.goGetRegistrationData(),
                        selectedProduct: RetrieveDefaultProduct(response?.data?.data?.Product),
                        Source: "WEBSITE",
                        offerId: response?.data?.data?.id
                    });
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });
    }, [])

    const data = useStaticQuery(graphql`
        query getResidentialPrivacyNotice {
            strapiPrivacyNotice {
                title
                text
            }
        }
    `);
    const privacyPolicy = getProperty(data, 'strapiPrivacyNotice');
    const privacyPolicyTextSplitted = privacyPolicy.text.split('Privacy Policy');

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
                title: '',
                titleOptions: [
                    { value: 'Dr', label: 'Dr' },
                    { value: 'Lady', label: 'Lady' },
                    { value: 'Lord', label: 'Lord' },
                    { value: 'Miss', label: 'Miss' },
                    { value: 'Mr', label: 'Mr' },
                    { value: 'Mrs', label: 'Mrs' },
                    { value: 'Ms', label: 'Ms' },
                    { value: 'Mx', label: 'Mx' },
                    { value: 'Prof', label: 'Prof' },
                    { value: 'Rev', label: 'Rev' }
                ],
                firstName: '',
                lastName: '',
                email: '',
                emailConfirmation: '',
                landlineNumber: '',
                mobileNumber: '',
                hearAboutUsValue: '',
                hearAboutUsOptions: [
                    { value: 'Installation Engineer', label: 'Installation Engineer' },
                    { value: 'Recommended by a neighbour', label: 'Recommended by a neighbour' },
                    { value: 'Leaflet/Letter', label: 'Leaflet/Letter' },
                    { value: 'Facebook', label: 'Facebook' },
                    { value: 'Instagram', label: 'Instagram' },
                    { value: 'LinkedIn', label: 'LinkedIn' },
                    { value: 'Email', label: 'Email' },
                    { value: 'Billboard', label: 'Billboard' },
                    { value: 'Radio', label: 'Radio' },
                    { value: 'News Article', label: 'News Article' },
                    { value: 'Search Engine', label: 'Search Engine' },
                    { value: 'Other', label: 'Other' }
                ],
                salesManagerQuestion: '',
                hearAboutUsOther: '',
                companyName: '',
                currentSpeed: '',
                preferredSpeed: '',
                dateOfConsent: new Date(),
                companyPosition: '',
                vat: null,
                numberOfMonthsLeft: '',
                utrNumber: null,
                inContractOptions: [
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' }
                ],
                switchingProviderValue: '',
                switchingProviderValueOther: '',
                agreeContact: false,
                shareData: false,
                businessSwitch: false,
                switchingProviderOptions: [
                    {
                        value: 'BT',
                        label: 'BT'
                    },
                    {
                        value: 'VIRGIN_MEDIA',
                        label: 'Virgin Media'
                    },
                    {
                        value: 'TALKTALK',
                        label: 'TalkTalk'
                    },
                    {
                        value: 'VODAFONE',
                        label: 'Vodafone'
                    },
                    {
                        value: 'SKY',
                        label: 'Sky'
                    },
                    {
                        value: 'OTHER',
                        label: 'Other'
                    },
                    {
                        value: 'I_HAVE_NO_ACTIVE_SERVICE',
                        label: 'I have no active connection'
                    }
                ]
            }}
            validationSchema={
                yup.object().shape({
                    currentSpeed: yup.string()
                        .required('This field is required'),
                    firstName: yup.string()
                        .required('This field is required'),
                    title: yup.string()
                        .required('This field is required'),
                    lastName: yup.string()
                        .required('This field is required'),
                    currentSpeed: yup.string()
                        .required('This field is required'),
                    preferredSpeed: yup.string()
                        .required('This field is required'),
                    switchingProviderValue: yup.string()
                        .required('This field is required'),
                    inContractValue: yup.string()
                        .required('This field is required'),
                    email: yup.string()
                        .matches(emailRegex, 'Please enter valid email address')
                        .required('This field is required'),
                    emailConfirmation: yup.string()
                        .matches(emailRegex, 'Please enter valid email address')
                        .oneOf([yup.ref('email'), null], 'Emails must match')
                        .required('This field is required'),
                    landlineNumber: yup.string()
                        .required('This field is required')
                        .matches(/^\d{1,14}$/, 'Enter number without special characters (ex. 07001234567).'),
                    mobileNumber: yup.string()
                        .matches(/^\d{1,14}$/, 'Enter number without special characters (ex. 07001234567).')
                        .required('Please enter your mobile number'),
                    hearAboutUsValue: yup.string()
                        .required('This field is required'),
                    hearAboutUsOther: yup.string()
                        .when("hearAboutUsValue", {
                            is: "Other",
                            then: yup.string().required("Please tell us how.")
                        }),
                    switchingProviderValueOther: yup.string()
                        .when("switchingProviderValue", {
                            is: "OTHER",
                            then: yup.string().required("Please enter provider's name.")
                        }),
                    numberOfMonthsLeft: yup.string()
                        .when("inContractValue", {
                            is: 'Yes',
                            then: yup.string().required("Please enter the number of months.")
                        }),
                    agreementSwitch: yup.boolean()
                        .oneOf([true]).required('We need your agreement here.')
                })
            }
            onSubmit={values => {
                values.hearAboutUsOther = 'Netomnia registration ' + values.hearAboutUsOther;

                const payload = {
                    ...gContext.goGetRegistrationData(),
                    Source: 'NETOMNIA',
                    offerId: gContext.goGetRegistrationData()?.offerId,
                    currentProvider: values.switchingProviderValue,
                    currentProviderOther: values.switchingProviderValueOther,
                    currentContractType: (values.inContractValue && values.inContractValue === 'Yes') ? 'IN_CONTRACT' : 'OUT_OF_CONTRACT'
                };

                const personalData = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber: values.landlineNumber,
                    mobileNumber: values.mobileNumber,
                    companyName: values.companyName,
                    companyPosition: values.companyPosition,
                    businessSwitch: values.businessSwitch,
                    currentSpeed: values.currentSpeed,
                    dateOfConsent: values.dateOfConsent,
                    utrNumber: values.utrNumber,
                    vat: values.vat,
                    numberOfMonthsLeft: values.numberOfMonthsLeft,
                    marketingConsent: values.marketingConsent,
                    agreementSwitch: values.agreementSwitch,
                    preferredSpeed: values.preferredSpeed,
                    title: values.title,
                    hearAboutUsOther: values.hearAboutUsOther,
                    hearAboutUsValue: values.hearAboutUsValue
                };

                const isBdukAndOrder = gContext.goGetRegistrationData()?.SearchPremiseResults.properties['IsBDUK'] === "true" &&
                    gContext.goGetRegistrationData()?.SearchPremiseResults.properties['SalesStatus'] === 'ORDER';

                (isBdukAndOrder ? API : APINO).post(
                    `/CrmModule/v1.0/db/batch`,
                    createLeadContactBillingAddressPayload(payload, personalData, isBdukAndOrder ? 'YF' : 'NO'),
                )
                    .then((response) => {

                        const leadId = extractLeadIdFromAPIResponse(response.data.data);
                        leadID = leadId
                        const contactId = extractContactIdFromAPIResponse(response.data.data);
                        const addressId = extractAddressIdFromAPIResponse(response.data.data);

                        if (typeof window !== 'undefined' && window) {
                            localStorage.removeItem('utmData');
                        }

                        return (isBdukAndOrder ? API : APINO).post(`/CrmModule/v1.0/db-associations/Lead/${leadId}?format=transformLevel2`,
                            prepareProductForLeadUpdate(gContext.goGetRegistrationData()?.selectedProduct)
                        )
                    })
                    .then(() => {
                        const buildStatus = gContext.goGetRegistrationData()?.SearchPremiseResults?.properties['BuildStatus'];
                        const salesStatus = gContext.goGetRegistrationData()?.SearchPremiseResults.properties['SalesStatus'];

                        if (salesStatus === 'ORDER') {
                            navigate("/success/", {
                                state: {
                                    pageData: {
                                        leadID,
                                        sprn: gContext.goGetRegistrationData()?.SearchPremiseResults?.properties['SPRN'],
                                        continueOrder: buildStatus === '8-RFS' ? true : false
                                    }
                                }
                            })
                        } else {
                            navigate("/success-bduk");
                        }
                    })
                    .catch((error) => {
                        // setIsCreatingRecords(false);
                        // setEmailChecked(false)
                        // setValidEmailAddress(false)
                        // setPhoneChecked(false)
                        // setValidPhoneNumber(false)
                    }
                    );
            }}
        >
            {({
                values,
                errors,
                handleChange,
                setFieldValue,
            }) => (

                <Form>
                    <Row>
                        <Col sm={12} lg={2}>
                            <Box mb={4} className="position-relative">
                                <Select
                                    autocomplete="off"
                                    defaultValue=""
                                    name="title"
                                    options={values.titleOptions}
                                    isSearchable={false}
                                    placeholder="Title"
                                    className="reactSelect"
                                    onChange={option => {
                                        setFieldValue("title", option.value)
                                    }
                                    }
                                />
                                {validateDropdown(errors.title)}
                            </Box>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Box mb={4} className="position-relative">
                                <Input
                                    type=""
                                    name="firstName"
                                    placeholder="First name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    className={errors.firstName ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.firstName)}
                            </Box>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Input
                                    type=""
                                    name="lastName"
                                    placeholder="Last name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    className={errors.lastName ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.lastName)}
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Input
                                    type=""
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    className={errors.email ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.email)}
                            </Box>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Input
                                    type=""
                                    name="emailConfirmation"
                                    placeholder="Confirm Email address"
                                    value={values.emailConfirmation}
                                    onChange={handleChange}
                                    className={errors.emailConfirmation ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.emailConfirmation)}
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Input type=""
                                    name="landlineNumber"
                                    placeholder="Landline"
                                    value={values.landlineNumber}
                                    onChange={handleChange}
                                    className={errors.landlineNumber ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.landlineNumber)}
                            </Box>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Input type=""
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    value={values.mobileNumber}
                                    onChange={handleChange}
                                    className={errors.mobileNumber ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.mobileNumber)}
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Input type=""
                                    name="currentSpeed"
                                    placeholder="Your current download speed (Mbps)"
                                    value={values.currentSpeed}
                                    onChange={handleChange}
                                    className={errors.currentSpeed ? 'is-invalid' : 'is-valid'}
                                />
                                {validateFeedback(errors.currentSpeed)}
                                <small><a href="https://www.speedtest.net/" target="_blank">Check your speed here - opens in a new tab</a></small>
                            </Box>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Select
                                    autocomplete="off"
                                    defaultValue=""
                                    name="preferredSpeed"
                                    options={preferredSpeeds}
                                    isSearchable={false}
                                    placeholder="Preferred package speed"
                                    className="reactSelect"
                                    onChange={option => {
                                        setFieldValue("preferredSpeed", option.value)
                                    }
                                    }
                                />
                                {validateDropdown(errors.preferredSpeed)}
                                <small>
                                    The speed you choose is indicative and not the speed you will receive. Upon ordering, you will be required to select your final package.
                                </small>
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Select
                                    autocomplete="off"
                                    defaultValue=""
                                    name="switchingProviderValue"
                                    options={values.switchingProviderOptions}
                                    isSearchable={false}
                                    placeholder="Who is your current broadband service provider?"
                                    className="reactSelect"
                                    onChange={option => {
                                        setFieldValue("switchingProviderValue", option.value)
                                        option.value === "OTHER" ? setSwitchingProviderAdditionalField(option.value) : setSwitchingProviderAdditionalField(false)
                                    }
                                    }
                                />
                                {validateDropdown(errors.switchingProviderValue)}
                            </Box>
                        </Col>

                        <Col sm={12} lg={6} className={`mx-auto ${switchingProviderAdditionalField ? '' : ' other-providers-placeholder'}`}>
                            <Box mb={4} className="position-relative">
                                <Input type=""
                                    name="switchingProviderValueOther"
                                    placeholder={switchingProviderAdditionalField ? "Enter provider's name" : ""}
                                    value={values.switchingProviderValueOther}
                                    onChange={handleChange}
                                    className={errors.switchingProviderValueOther ? 'is-invalid' : 'is-valid'}
                                    style={{
                                        backgroundColor: switchingProviderAdditionalField ? '#ffffff' : '#efefef',
                                        opacity: switchingProviderAdditionalField ? '1' : '0.4'
                                    }}
                                    disabled={!switchingProviderAdditionalField}
                                />
                                {validateFeedback(errors.switchingProviderValueOther)}
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Select
                                    autocomplete="off"
                                    name="inContractValue"
                                    options={values.inContractOptions}
                                    defaultValue=""
                                    isSearchable={false}
                                    placeholder="Are you in contract?"
                                    className="reactSelect"
                                    onChange={option => {
                                        setFieldValue("inContractValue", option.value)
                                        setInContractField(option.value)
                                    }
                                    }
                                />
                                {validateDropdown(errors.inContractValue)}
                            </Box>
                        </Col>

                        <Col sm={12} lg={6} className={`mx-auto ${switchingProviderAdditionalField ? '' : ' other-providers-placeholder'}`}>
                            <Box mb={4} className="position-relative">
                                <Input
                                    type="text"
                                    maxLength="2"
                                    name="numberOfMonthsLeft"
                                    placeholder={(inContractField == 'Yes') ? "How many months remaining?" : ""}
                                    value={values.numberOfMonthsLeft}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setFieldValue("numberOfMonthsLeft", e.target.value)
                                        }
                                    }}
                                    className={errors.numberOfMonthsLeft ? 'is-invalid' : 'is-valid'}
                                    style={{
                                        backgroundColor: (inContractField == 'Yes') ? '#ffffff' : '#efefef',
                                        opacity: (inContractField == 'Yes') ? '1' : '0.4'
                                    }}
                                    disabled={(inContractField !== 'Yes')}
                                />
                                {validateFeedback(errors.numberOfMonthsLeft)}
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6}>
                            <Box mb={4} className="position-relative">
                                <Select
                                    autocomplete="off"
                                    name="hearAboutUsValue"
                                    options={values.hearAboutUsOptions}
                                    // defaultValue={{value: 'Other', label: 'Other'}}
                                    defaultValue={""}
                                    isSearchable={false}
                                    placeholder="How did you hear about us?"
                                    className="reactSelect"
                                    onChange={option => {
                                        setFieldValue("hearAboutUsValue", option.value)
                                        if (
                                            option.value === "Other"
                                        ) {
                                            setHearAboutUsAdditionalField(option.value)
                                        } else {
                                            setHearAboutUsAdditionalField(false)
                                        }
                                    }
                                    }
                                />
                                {validateDropdown(errors.hearAboutUsValue)}
                            </Box>
                        </Col>

                        <Col sm={12} lg={6} className={`mx-auto ${hearAboutUsAdditionalField ? '' : ' other-providers-placeholder'}`}>
                            <Box mb={4} className="position-relative">
                                <Input type=""
                                    name="hearAboutUsOther"
                                    placeholder={constructHearAboutUsPlaceholder(values.hearAboutUsValue)}
                                    value={values.hearAboutUsOther}
                                    onChange={handleChange}
                                    className={errors.hearAboutUsOther ? 'is-invalid' : 'is-valid'}
                                    style={{
                                        backgroundColor: hearAboutUsAdditionalField ? '#ffffff' : '#efefef',
                                        opacity: hearAboutUsAdditionalField ? '1' : '0.4'
                                    }}
                                    disabled={!hearAboutUsAdditionalField}
                                />
                                {validateFeedback(errors.hearAboutUsOther)}
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={12} className="text-left consent-agreement">
                            <BusinessSwitch
                                name="businessSwitch"
                                value={values.businessSwitch}
                                setFieldValue={setFieldValue}
                                className={['m-0 mr-2', (errors.businessSwitch ? 'is-invalid' : 'is-valid')]}
                            />
                            <Text className="legal-text">I am a business user</Text>
                            <span style={{
                                position: 'relative',
                                bottom: '22px'
                            }}></span>
                        </Col>
                    </Row>

                    {
                        values.businessSwitch &&
                        <>
                            <Row className="mt-3">
                                <Col sm={12} lg={6}>
                                    <Box mb={4} className="position-relative">
                                        <Input
                                            type=""
                                            name="companyName"
                                            placeholder="Business Name"
                                            value={values.companyName}
                                            onChange={handleChange}
                                            className={errors.companyName ? 'is-invalid' : 'is-valid'}
                                        />
                                        {validateFeedback(errors.companyName)}
                                    </Box>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Box mb={4} className="position-relative">
                                        <Input
                                            type=""
                                            name="companyPosition"
                                            placeholder="Company Role"
                                            value={values.companyPosition}
                                            onChange={handleChange}
                                            className={errors.companyPosition ? 'is-invalid' : 'is-valid'}
                                        />
                                        {validateFeedback(errors.companyPosition)}
                                    </Box>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <Box mb={4} className="position-relative">
                                        <Input
                                            type=""
                                            name="utrNumber"
                                            placeholder="Company / UTR number"
                                            value={values.utrNumber}
                                            onChange={handleChange}
                                            className={errors.utrNumber ? 'is-invalid' : 'is-valid'}
                                        />
                                        {validateFeedback(errors.utrNumber)}
                                    </Box>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Box mb={4} className="position-relative">
                                        <Input
                                            type=""
                                            name="vat"
                                            placeholder="VAT"
                                            value={values.vat}
                                            onChange={handleChange}
                                            className={errors.vat ? 'is-invalid' : 'is-valid'}
                                        />
                                        {validateFeedback(errors.vat)}
                                    </Box>
                                </Col>
                            </Row>
                        </>
                    }

                    <Row>
                        <Col sm={12} lg={12} className="text-left consent-agreement">
                            <MarketingConsent
                                name="marketingConsent"
                                value={values.marketingConsent}
                                setFieldValue={setFieldValue}
                                className={['m-0 mr-2', (errors.marketingConsent ? 'is-invalid' : 'is-valid')]}
                            />
                            <Text className="legal-text">Do you consent to receiving Marketing materials from us or our partners.</Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={12} className="text-left consent-agreement">
                            <AgreementSwitch
                                name="agreementSwitch"
                                value={values.agreementSwitch}
                                setFieldValue={setFieldValue}
                                className={['m-0 mr-2', (errors.agreementSwitch ? 'is-invalid' : 'is-valid')]}
                            />
                            <Text className="legal-text">
                                I understand and consent to Netomnia using my information<br />
                                above to update me about offers, products and services in
                                accordance with <Link to="/legal/privacy-and-cookie-policy/">Netomnia’s Privacy Policy</Link>.
                            </Text>
                            {validateAgreement(errors.agreementSwitch)}
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={12} lg={6} className="mb-3 mt-5">
                            <Button width="100%" type="submit" borderRadius={10} className="mx-auto">
                                Register
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )

}

export default BdukForm;
